// Não se esqueça de recompilar os estilos para poder ver as alterações feitas aqui!
//
// Você pode fazer isso pelo terminal de duas maneiras:
//
// npm run dev
// *Irá recompilar uma única vez todos os estilos e scripts do projeto*
//
// npm run watch-poll
// *Irá recompilar todos os estilos e scripts do projeto a primeira vez, e depois continuará rodando de fundo, e cada vez que você alterar um arquivo CSS ou JS e salvar,
//  ele irá compilar apenas o que você alterou!*
// @font-face {
//     font-family: helveticaneue;
//     src: url('HelveticaNeueLTStd-UltLt.otf');
// }
// @import "cores";
@import "cores";
#faixa-azul {
    background-color: $azul-claro;
    font-family: montserrat;
    h2,
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 34px;
        color: $preto;
        font-family: montserrat;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $preto;
        font-family: montserrat;
    }
}

.esquerda {
    h4 {
        font-family: montserrat;
        color: $preto;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
    }
    h6 {
        font-family: montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #696969;
    }
    .bloco-color {
        font-family: montserrat;
        color: #696969;
        background: #f5f5f5;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
    }
    .arrow {
        position: relative;
        top: 3px;
    }
    .card {
        background-color: $cinza;
        border-radius: 10px;
        position: relative;
        // top: -15rem;
        transition: all 0.9s;
        border: none;
        .imagem-flutuante {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            min-height: 13rem;
            height: 100%;
            width: 100%;
            border-radius: 10px 10px 0 0;
        }
        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 21px;
            color: $preto;
            font-family: montserrat;
        }
        li {
            font-family: montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            color: #696969;
        }
        p {
            font-family: montserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #696969;
        }
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #696969;
            font-family: montserrat;
        }
        .icone-card {
            max-width: 2rem;
            color: $azul-medio;
        }
        .arrow {
            position: relative;
            top: 4px;
        }
        h2 {
            font-family: montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 38px;
            line-height: 39px;
            color: $branco;
        }
        a {
            font-family: montserrat;
            text-decoration: none;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: $preto !important;
            transition: all 0.9s;
            background-color: $amarelo;
            border-radius: 10px;
            padding: 20px 40px;
        }
        a:hover {
            background-color: $laranja;
            // box-shadow: 0px 8px 28px 0px $azul-escuro;
            color: $branco !important;
            font-weight: 900;
            // transform: scale(1.1);
            transition: all 0.9s;
        }
    }
    .card:hover {
        box-shadow: 0px 8px 28px 0px $cinza2;
        border: 2px solid $azul-medio;
        box-shadow: 0px 8px 28px 0px $azul-escuro;
        transition: all 0.9s;
    }
}

.direita {
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        color: $preto;
        font-family: montserrat;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #696969;
        font-family: montserrat;
    }
    .botao-filtro {
        font-family: montserrat;
        border: 2px solid $azul-escuro;
        background-color: $branco;
        color: $azul-escuro !important;
        border-radius: 10px;
        padding: 15px 30px;
        margin-top: 20px;
        transition: all 0.9s;
        font-weight: 700;
        .click {
            background-image: url("/img/home/click1.png");
            background-repeat: no-repeat;
            background-position: center;
            padding: 3rem 0rem;
            transition: all 0.9s;
        }
    }
    .botao-filtro:hover {
        .click {
            background-image: url("/img/home/click2.png");
            background-repeat: no-repeat;
            background-position: center;
            padding: 3rem 0rem;
            transition: all 0.9s;
        }
        background-color: $azul-medio;
        color: $branco !important;
        transition: all 0.9s;
        font-weight: 800;
    }
}

#individual {
    background-image: url("/img/home/back1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 4rem 0rem;
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 39px;
        color: $branco;
        font-family: montserrat;
    }
    .card {
        background-color: $cinza;
        border-radius: 10px;
        position: relative;
        // top: -15rem;
        transition: all 0.9s;
        border: none;
        height: 34rem;
        .imagem-flutuante {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            min-height: 13rem;
            height: 100%;
            width: 100%;
            border-radius: 10px 10px 0 0;
        }
        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 25px;
            color: $azul-escuro;
            font-family: montserrat;
        }
        h5 {
            font-family: montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 25px;
            color: #696969;
        }
        h6 {
            font-family: montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 19px;
            color: #696969;
        }
        .arrow {
            position: relative;
            top: 4px;
        }
        a {
            font-family: montserrat;
            text-decoration: none;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: $preto;
            transition: all 0.9s;
            background-color: $amarelo;
            border-radius: 10px;
            padding: 20px 40px;
        }
        a:hover {
            background-color: $laranja;
            // box-shadow: 0px 8px 28px 0px $azul-escuro;
            color: $branco;
            font-weight: 900;
            // transform: scale(1.1);
            transition: all 0.9s;
        }
    }
    .botao-filtro {
        font-family: montserrat;
        border: 2px solid $branco;
        background-color: transparent;
        color: $branco !important;
        border-radius: 10px;
        padding: 15px 30px;
        margin-top: 20px;
        transition: all 0.9s;
        font-weight: 700;
        .click {
            background-image: url("/img/home/click2.png");
            background-repeat: no-repeat;
            background-position: center;
            padding: 3rem 0rem;
            transition: all 0.9s;
        }
    }
    .botao-filtro:hover {
        .click {
            background-image: url("/img/home/click1.png");
            background-repeat: no-repeat;
            background-position: center;
            padding: 3rem 0rem;
            transition: all 0.9s;
        }
        background-color: $branco;
        color: $azul-escuro !important;
        transition: all 0.9s;
        font-weight: 800;
    }
}

#especialidade {
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $azul-claro;
        font-family: montserrat;
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 43px;
        line-height: 49px;
        color: $preto;
        font-family: montserrat;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #696969;
        font-family: montserrat;
    }
    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $preto;
        font-family: montserrat;
    }
    .botao {
        margin-right: 21rem;
    }
    a {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $branco;
        font-family: montserrat;
        padding: 20px 50px;
        background: linear-gradient(93.62deg, #072760 0%, #59c9f3 107.95%);
        border-radius: 10px;
        transition: all 0.9s;
    }
    a:hover {
        background: #59C9F3!important;
        color: #072760!important;
        transition: all 0.9s;
        font-weight: 600;
    }
}

#equipe {
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 39px;
        color: $preto;
        font-family: montserrat;
    }
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #696969;
        font-family: montserrat;
    }
    a {
        text-decoration: none;
        border: 1px solid #072760;
        color: #072760!important;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        font-family: montserrat;
        transition: all 0.9s;
    }
    a:hover {
        background-color: #072760;
        border: 1px solid #072760;
        color: #FFFFFF!important;
    }
    a {
        text-decoration: none;
        border: 1px solid #072760;
        color: #072760!important;
    }
    .card {
        background-color: #e8eaf3;
        border-radius: 20px;
        border: none;
        height: 27rem;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            line-height: 21px;
            color: $preto;
            font-family: montserrat;
        }
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $preto;
            font-family: montserrat;
        }
    }
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 40%;
    transform: translateY(-60%);
}

.owl-prev {
    left: -2rem;
}

.owl-next {
    right: -2rem;
}

#comentarios {
    background: $azul-claro;
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 39px;
        color: $preto;
        font-family: montserrat;
    }
}

#parceiros {
    h2 {
        font-family: montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 34px;
        color: $preto;
    }
    p {
        font-family: montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #696969;
    }
}

#final {
    .back-final {
        background-image: url("/img/img-final.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 170px 0px 170px 100px;
        h2 {
            font-family: montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 38px;
            line-height: 39px;
            color: $branco;
        }
        a {
            font-family: montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-decoration: none;
            color: $azul-escuro;
            background-color: $branco;
            border-radius: 10px;
            padding: 20px 40px;
            transition: all 0.9s;
        }
        .botao {
            margin-top: 3rem;
        }
        a:hover {
            background-color: $azul-escuro;
            box-shadow: 0px 8px 28px 0px $azul-escuro;
            color: $branco;
            transform: scale(1.1);
            transition: all 0.9s;
        }
    }
}

@media (max-width: 1399px) {
    #faixa-azul {
        h2,
        h3 {
            font-size: 25px;
        }
        p {
            font-size: 18px;
        }
    }
    #especialidade {
        h4 {
            font-size: 15px;
        }
        h2 {
            font-size: 39px;
        }
        p {
            font-size: 15px;
        }
        .botao {
            margin-right: 13rem;
        }
    }
    #equipe {
        h2 {
            font-size: 35px;
        }
        h3 {
            font-size: 16px;
        }
    }
    #final .back-final h2 {
        font-size: 35px;
        line-height: 39px;
    }
}

#redes {
    h2 {
        font-family: montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        color: #000000;
    }
    ion-icon {
        color: #0066B2;
        margin-bottom: -4px;
        margin-left: 0.7rem;
        font-size: 30px;
    }
}

@media (max-width: 1199px) {
    #final .back-final h2 {
        font-size: 35px;
        line-height: 39px;
    }
}

@media (max-width: 1030px) {
    #final .back-final {
        font-size: 17px;
    }
}

@media (max-width: 991px) {
    #especialidade .botao {
        margin-right: 0rem;
    }
    #parceiros {
        margin-top: 5rem;
    }
    #final .back-final {
        background-image: url(/img/img-final.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 170px 0px 170px 0px;
    }
}

@media (max-width: 768px) {
    #comentarios h2 {
        font-size: 30px;
        line-height: 39px;
    }
    #final .back-final h2 {
        font-size: 30px;
        line-height: 39px;
    }
}

@media (max-width: 575px) {
    #comentarios h2 {
        font-size: 30px;
        line-height: 39px;
    }
    #equipe h2 {
        font-size: 32px;
    }
}

@media (max-width: 440px) {}

@media (max-width: 330px) {}

.count-cursos::after {
    font: 800 40px system-ui;
    content: counter(count);
    animation: counter 5s linear infinite alternate;
    counter-reset: count 0;
}

@keyframes counter {
    0% {
        counter-increment: count 0;
    }
    10% {
        counter-increment: count 1;
    }
    20% {
        counter-increment: count 2;
    }
    30% {
        counter-increment: count 3;
    }
    40% {
        counter-increment: count 4;
    }
    50% {
        counter-increment: count 5;
    }
    60% {
        counter-increment: count 6;
    }
    70% {
        counter-increment: count 7;
    }
    80% {
        counter-increment: count 8;
    }
    90% {
        counter-increment: count 9;
    }
    100% {
        counter-increment: count 10;
    }
}